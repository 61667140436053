function getAge(){
	return Math.floor(Math.abs((new Date(1994,5,30) - new Date())) / (1000 * 3600 * 24*365.25));
}
function getCurrentShortDescription(){
	return "Étudiant et Entrepreneur";
}
function getPhoto(){
	return "img/photo.jpg";
}
function getAddress (){
	return ["639, Rue Emile-Girardin","Apt. 116","Chicoutimi (Québec) G7H 6R1","CANADA"]
}
function getMail(){
	return "kevin.chapron@ellensee.ca";
}
function getPhone(){
	return "+1 (418) 815-2789";
}
function getSocialMedias(){
	return [
		{"link":"https://www.facebook.com/chapronk","icon":"fab fa-facebook-f"},
		{"link":"https://www.linkedin.com/in/kevinchapron/","icon":"fab fa-linkedin-in"},
		{"link":"https://www.instagram.com/kchapron/","icon":"fab fa-instagram"},
		{"link":"https://researchgate.net/profile/Kevin_Chapron","icon":"fab fa-researchgate"},
		{"link":"https://github.com/kevinchapron","icon":"fab fa-github"},
		{"link":"https://discord.gg/C54RkwY","icon":"fab fa-discord"},
	];
}
function getExperiences(){
	return [
		{
			"date":{"start":{"year":2018,"month":3},"end":null},
			"title_exp":"Cofondateur et Directeur général",
			"title_post":"Technologies EllenSee Inc.",
			"additionnal_data":null,
			"data":"J'ai fondé cette société avec mon collègue Baptiste Lemarcis pour permettre d'améliorer la qualité de vie de nos aînés, par le biais de nouvelles technologies. " +
				"Notre premier produit est un bracelet permettant de détecter de façon automatique les chutes, ainsi que les anomalies de santé liées au coeur ou à la respiration. " +
				"Il permet aussi de détecter les anomalies comportementales à travers le temps, via les activités réalisées par son porteur.<br />" +
				"Pour plus d'infos, me contacter."
		},
		{
			"date":{"start":{"year":2017,"month":4},"end":null},
			"title_exp":"Président",
			"title_post":"Club associatif « SnowSki UQAC »",
			"additionnal_data":null,
			"data":"Auparavant membre du comité exécutif de ce club, j'ai été promu Président afin de gérer l'ensemble de ses actions. " +
				"Les tâches que je dois réaliser comprennent le recrutement du comité, sa gestion tout au long de l'année, les contrats avec les montagnes, la gestion financière du club (environ 25.000 $) ainsi que la gestion des 350 membres annuels.<br />" +
				"Pour plus d'informations, visitez <a target='_blank' href='http://snowskiuqac.com'>http://snowskiuqac.com</a>"
		},
		{
			"date":{"start":{"year":2018,"month":3},"end":{"year":2018,"month":3}},
			"title_exp":"Juge pour la finale régionale",
			"title_post":"Expo-Sciences Hydro-Québec — Métabetchouan-Lac-à-la-Croix",
			"additionnal_data":null,
			"data":"J'ai été invité pour juger les jeunes à travers leurs travaux, afin de promouvoir la relève scientifique. "
		},
		{
			"date":{"start":{"year":2016,"month":1},"end":{"year":2017,"month":4}},
			"title_exp":"Enseignant pour la Concentration Informatique",
			"title_post":"Séminaire de Chicoutimi",
			"additionnal_data":null,
			"data":"Lors de ce contrat, j'ai enseigné à plusieurs groupes d'élèves âgés de 15 à 15 ans. " +
				"Ils ont appris les bases du développement web, via du HTML, CSS et du JavaScript. " +
				"Ils ont aussi appris à naviguer dans les sytèmes Unix et à utiliser un terminal. " +
				"Leur projet final était de réaliser un Pac-Man via les canvas natifs de JavaScript."
		},
		{
			"date":{"start":{"year":2016,"month":9},"end":{"year":2017,"month":5}},
			"title_exp":"Encadrant d'un élève de secondaire",
			"title_post":"Expo-Sciences Hydro-Québec",
			"additionnal_data":null,
			"data":"J'ai été choisi pour encadrer un élève de secondaire tout au long de son Expo-Sciences. " +
				"Il a pu concevoir un système de détection de chutes, ce qui lui a permis de gagner les compétitions locales, régionales et provinciales. " +
				"Sélectionné pour la compétition pancanadienne, il a pu présenter ses travaux devant le Premier Ministre canadien Justin Trudeau."
		},
		{
			"date":{"start":{"year":2017,"month":1},"end":{"year":2017,"month":11}},
			"title_exp":"Développeur/Concepteur d'application",
			"title_post":"CEE-UQAC",
			"additionnal_data":null,
			"data":"Pour un contrat avec le réseau Technosciences, nous avons dû concevoir et développer trois trousses d'apprentissage. " +
				"Ces trousses permettent aux jeunes du primaire et du secondaire de s'initier au développement informatique. " +
				"De plus, elles sont respectivement prévues pour des jeunes de 6-9 ans, 9-12 ans et 12-15 ans. " +
				"Depuis 2018, le projet a reçu l'intérêt et le support d'Ubisoft, permettant ainsi à plus de 8.000 jeunes québecois de s'initier au développement informatique."
		},
		{
			"date":{"start":{"year":2015,"month":6},"end":{"year":2015,"month":9}},
			"title_exp":"Développeur web",
			"title_post":"CEE-UQAC",
			"additionnal_data":null,
			"data":"J'ai été engagé pour le développement d'un projet permettant la gestion de Résidences Privées pour Ainés, système semblable à un ERP. " +
				"Pour ce faire, j'ai développé le Backend en Python (Django) et le Frontend en AngularJS. " +
				"Également, traitant des données confidentielles, tout le transit de données a été sécurisé, ainsi que leur accès en fonction de l'utilisateur et de ses permissions (OAuth 2.0)."
		},

		{
			"date":{"start":{"year":2012,"month":10},"end":{"year":2013,"month":7}},
			"title_exp":"Professeur bénévole d'informatique",
			"title_post":"Club d'Informatique du Fontenay (CIFON)",
			"additionnal_data":null,
			"data":"J'ai donné des cours de façon bénévole à nos aînés, afin de leur apprendre à apprécier la technologie, en leur apprenant comment manipuler un ordinateur. " +
				"Ils ont pu écrire du texte, stocker leurs photos, créer des calendriers, converser avec leur famille, etc."
		},
	];
}

function getFormations(){
	return [
		{
			"date":{"start":{"year":2017,"month":9},"end":null},
			"title_exp":"Doctorat en Sciences et Technologies de l’Information",
			"title_post":"Étudiant à l’Université du Québec à Chicoutimi (UQAC)",
			"additionnal_data":null,
			"data":"Mes travaux consistent en la réalisation d'un kit de capteurs déployables facilement, afin d'améliorer un habitat pour le rendre intelligent. " +
				"Ce kit contiendra des capteurs créés pour l'occasion ainsi qu'une Intelligence Artificielle basique afin de traiter les données collectées." +
				"Il peut être utilisé par d'autres chercheurs (voir mes articles), pour faire grandir la recherche dans le domaine des habitats intelligents, et ce, pour un moindre coût.<br />" +
				"Pour réaliser ce doctorat, je suis affilié au <a href='http://liara.uqac.ca'>Laboratoire d'Intelligence Ambiante pour la Reconnaissance d'Activités (LIARA)</a>."
		},
		{
			"date":{"start":{"year":2015,"month":9},"end":{"year":2017,"month":12}},
			"title_exp":"Maîtrise en Informatique – Profil Recherche",
			"title_post":"Étudiant à l’Université du Québec à Chicoutimi (UQAC)",
			"additionnal_data":"Mention Excellence du jury",
			"data":"Mes travaux consistent en la réalisation d'un dispositif portatif pour reconnaître les activités de la vie quotidienne d'une personne, ainsi que des exercice physiques spécifiques." +
				"Ces exercices sont généralement demandés par des physiothérapeutes pour diminuer le déclin physique d'un patient." +
				"Pour ce faire, j'ai développé un bracelet communiquant par le biais de Bluetooth Low Energy, qui a fait l'object d'un article de conférence (voir article: UIC 2017)." +
				"Lors de cette conférence, mon projet a attiré l'attention des examinateurs: j'ai été invité à publier dans le prestigieux journal Sensors.<br />" +
				"Pour réaliser ce doctorat, je suis affilié au <a href='http://liara.uqac.ca'>Laboratoire d'Intelligence Ambiante pour la Reconnaissance d'Activités (LIARA)</a>."
		},
		{
			"date":{"start":{"year":2014},"end":{"year":2015}},
			"title_exp":"Baccalauréat en Informatique / Licence en Informatique / DUETI",
			"title_post":"Étudiant à l’Université du Québec à Chicoutimi (UQAC) & à l’Université du Havre",
			"additionnal_data":null,
			"data":"Université partenaire de mon université française, l'UQAC s'est révélée être mon choix pour étudier à l'étranger." +
				"Ce voyage d'études m'a permis de valider trois diplômes en un an : le baccalauréat québecois, la licence française et le DUETI."
		},
		{
			"date":{"start":{"year":2012},"end":{"year":2014}},
			"title_exp":"DUT Informatique",
			"title_post":"Étudiant à l’Université du Havre (IUT du Havre)",
			"additionnal_data":null,
			"data":"Mon premier choix d'études a toujours été l'Informatique et j'ai eu la chance de pouvoir commencer à étudier dans ce domaine dès mon entrée à l'Université, grâce à l'IUT du Havre."
		},
	];
}
function getAboutMe(){
	let p = document.createElement("p");
	p.innerText = "Étudiant né en France le 30 Mai 1994, j'ai emménagé au Québec afin de parfaire ma connaissance de l'Informatique et de l'Intelligence Artificielle. C'est pourquoi je suis désormais au LIARA, pour réaliser mon Doctorat en Sciences et Technologies de l'Information.";
	return p.outerHTML;
}

function getSkills(){
	return [
		{"name":"Intelligence Artificielle","details":null},
		{"name":"Gestion de projets","details":null},
		{"name":"Programmation web","details":"HTML, CSS, JavaScript, JQuery, PHP, etc."},
		{"name":"Frameworks","details":"Django, Flask, AngularJS, VueJS, etc."},
		{"name":"Programmation générale","details":"GoLang, Python, C++, NodeJS, Java, etc."},
		{"name":"Bases de données","details":"MariaDB, PostgreSQL, etc."},
		{"name":"Programmation réseau","details":"Wi-Fi, DNS, UDP, TCP, HTTP, Bluetooth, Bluetooth LE)"},
		{"name":"Microcontroleur","details":"Arduino, mBed"},
		{"name":"Enseignement","details":null},
		{"name":"Bureautique & Systèmes","details":"Windows, Unix"},
	];
}
function getInterests(){
	return [
		{"name":"Judo","details":"environ 15 ans"},
		{"name":"Aviron","details":"2 ans de compétition"},
		{"name":"Ski Alpin","details":"depuis l'année 2000"},
		{"name":"Nouvelles technologies","details":null},
		{"name":"Enseignement","details":null},
	];
}
function getDivers(){
	return [
		{"name":"Permis de conduire","details":null},
		{"name":"Certification DigitalActive","details":"Google"},
		{"name":"24H des IUT informatique","details":"Vainqueur de l'édition 2014"},
	];
}
function getPublications(){
	return {
		"journals":[
			"<b>K. Chapron</b>, V. Plantevin, and F. Thullier, “A More Efficient Transportable and Scalable System for Real-Time Activities and Exercises Recognition,” Sensors, vol. 18, no. 1, p.268, 2018.",
		],
		"proceedings":[
			"<b>K. Chapron</b>, K. Bouchard, E. Duchesne, and S. Gaboury, “Transportable and Scalable System for Activities and Exercises Recognition in Real-Time,” in 14th IEEE International Conference on Ubiquitous Intelligence and Computing, 2017, p. 7.",
			"M. Boivin, M. Bolduc, P. Giroux, V. Porta-Scarta, L. Côté, <b>K. Chapron</b>, S. Hallé, “Développement d’ateliers visant l'initiation à la programmation en contexte parascolaire”, Symposium du GRIIPTIC : Comment le numérique peut-il participer au développement de compétences?, 2018.",
			"M. Bouchard-Aubin, J. Bouchard, S. Gaboury, <b>K. Chapron</b>, J. Dolbec, “NAO humanoid robot for cognitive screening in elderly”, in 23rd Annual CyberPsychology, CyberTherapy & Social Networking Conference, 2018.",
		],
		"thesis":[
			"<b>K. Chapron</b>. “Méthode hybride pour la reconnaissance d’activités de la vie quotidienne et d’exercices physiques en temps réel,” 2017. Thèse de maîtrise. Université du Québec à Chicoutimi.",
		],
	}
}